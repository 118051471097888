<template>
  <v-container>
    <page-title title="編輯Line@訊息" />
    <eagle-form :form-key="formKey" >
    </eagle-form>
  </v-container>
</template>

<script lang="babel" type="text/babel">
import formMixin from '@/components/form/mixins/formMixin'
import formConfig from './providerLineMessageEdit'

export default {
  mixins: [formMixin],
  components: {

  },
  
  data: () => ({
    formKey: 'provider-line-message-edit-form',
    meta: {},
    terms: `歡迎您使用Linerp徠能智慧採購系統（以下稱本系統），係由宙和網路資訊股份有限公司 (以下簡稱「本公司」)依據本服務條款提供服務，請先詳細閱讀以下約定條款：
      
      當您使用者使用本系統時，即視為您已經充分審閱、瞭解及同意本服務條款，並同意並遵守本條款及「隱私權聲明」之所有內容。
      
      若您未滿二十歲之未成年人或依中華民國法律須事先得第三人同意始得行使權利或負擔義務者，則應於您的家長（或監護人）閱讀、瞭解並同意本條款之所有內容，方得使用本系統。當您使用本系統時，及表示您的家長（或監護人）已閱讀、瞭解並同意本條款之所有內容。如您不同意本條款之全部或部分內容，請勿註冊，並請立即停止使用本系統。
      
      使用本系統服務，您同意以下事項：
      
      一、註冊義務與會員服務之使用
      1. 當您註冊成為網站會員，或於本系統購物，或使用本系統所提供之服務與參加活動時，我們會要求您填寫個人資料，如姓名、性別、電話…。
      2. 請妥善保管帳號，不得透露或提供、出借轉讓給第三人使用，若帳號遭人冒用，請立即通知客服人員。
      3. 若您提供任何不實或不完整的資料或使用無效之信用卡號碼在網站上進行交易活動或帳號遭人冒用，如造成您任何損害本公司不予負責，如造成本公司損害，您可能還會牽涉相關法律及賠償責任，本公司並有權暫停或終止您的帳號，並拒絕您使用會員服務之全部或一部份。
      4. 本公司對於「會員」所登錄之資料及交易紀錄，基於保障個人隱私權，將供本系統之所需，及本系統行銷統計、分析、會員服務外，不作其他任何目的使用。
      5. 本公司對所有會員使用本系統服務將會依法保障隱私。
      6. 本系統帳號及個資安全請參閱「隱私權聲明」。
      
      二、服務內容的變更
      會員同意徠能得隨時調整、變更、修改或終止本系統及本條款，於徠能公告後生效，不再另行個別通知。會員因參與徠能活動及使用徠能服務，而與徠能所發生之權利義務關係，均以本條款最新修訂之版本為準。
      
      三、會員的守法義務及承諾
      會員承諾絕不為任何非法目的或以任何非法方式使用本系統，並承諾遵守中華民國相關法規及一切使用網際網路之國際慣例。會員若係中華民國以外之使用者，並同意遵守所屬國家或地域之法令。會員同意並保證不得利用本系統從事侵害他人權益或違法之行為，包括但不限於：
      1. 公布或傳送任何誹謗、侮辱、具威脅性、攻擊性、不雅、猥褻、不實、違反公共秩序或善良風俗或其他不法之文字、圖片或任何形式的檔案。
      2. 侵害或毀損本公司或他人名譽、隱私權、營業秘密、商標權、著作權、專利權、其他智慧財產權及其他權利。
      3. 違反依法律或契約所應負之保密義務。
      4. 冒用他人名義使用本系統。
      5. 傳輸或散佈電腦病毒。
      6. 從事未經徠能事前授權的商業行為。
      7. 刊載、傳輸、發送垃圾郵件、連鎖信、違法或未經徠能許可之多層次傳銷訊息及廣告等；或儲存任何侵害他人智慧財產權或違反法令之資料。
      8. 對本系統其他會員或第三人產生困擾、不悅或違反一般網路禮節致生反感之行為。
      9. 其他不符本系統所提供的使用目的之行為或徠能有正當理由認為不適當之行為。
      
      四、購物須知
      1. 除本服務條款外，商品銷售網頁及訂購流程中相關網頁上所呈現之相關資訊，包括相關交易條件、限制及說明等，亦為契約之一部分。
      2. 您一旦依照本系統所定方式、條件及流程完成訂購程序，就表示您提出要約、願意依照本服務服務條款及相關網頁上所載明的約定內容、交易條件或限制，訂購該商品或服務。
      3. 訂單成立後，系統會透過電子郵件或其他方式寄給您一封通知，但所有訂單仍須視乎相關供貨狀況。
      4. 訂單確認後，若在派送貨品期間更改或取消訂單，須立即聯繫客服協助進行相關處理，無故拒收者本系統有權暫停或終止任何會員服務及權利。
      5. 商品配送若無法送達，或經約定期限通知領取而拒絕領取或無法取得聯繫者，將視為您同意拋棄物品，並負擔運費及相關衍生費用，且不再另行通知，事後不得要求商品、退款、損害賠償。
      6. 運送方式均有告知貨到所需時間，若因颱風、地震…等天災、事變及其他不可抗力之因素而延遲則不在此限。
      
      五、退/換貨說明
      1. 依消費者保護法第19條規定，通訊交易商品享有貨到7天猶豫期(非試用期)，得於收受商品或接受服務後7日內，以退回商品或書面通知方式解除契約，無須說明理由及負擔任何費用或對價。
      2. 商品貨到後，如有瑕疵、破損、物件短缺…等情形，需辦理退換貨，請於7日內以書面通知或退回商品方式退換貨，並請留下聯絡方式或與客服聯繫後續細節以利退換貨速度，超過7日恕不接受退換貨。
      3. 所有退回商品，連同贈品、附件、包裝、及附隨文件資料，必須完整保持為出貨狀態當時的完整性，如有索取實體發票，實體發票須一併退回及簽署折讓單等相關法令所要求之單據，否則，本公司有權拒絕受理。
      4. 您瞭解並同意，若因您要求退貨或換貨、或因本公司無法接受您全部或部分之訂單、或契約因故解除或失其效力，而需為您辦理退款事宜時，本公司得代您處理發票或折讓單等相關法令所要求之單據。
      5. 若您申請退貨退款，商品收回後經本公司確認與您申請退貨商品數量及完整性，連同贈品、附件、包裝、及附隨文件資料無誤後，本公司退款作業會在7-14個工作日內退款。
      6. 確認符合退款標準後，若您使用信用卡、銀聯卡、LINE PAY付款，會進行線上刷退，退款實際入帳日期，依各發卡銀行入帳日不同有所差異，請您洽詢發卡銀行確認實際入帳日；若您使用超商代碼繳費付款或超過發卡行結帳日期，請您提供正確的存摺帳戶影本，以利完成退款手續。
      
      六、智慧財產權的保護
      1. 徠能所使用之軟體或程式、網站上所有內容，包括但不限於著作、圖片、檔案、資訊、資料、網站架構、網站畫面的安排、網頁設計，均由徠能或其他權利人依法擁有其智慧財產權，包括但不限於商標權、專利權、著作權、營業秘密與專有技術等。任何人不得逕自使用、修改、重製、公開播送、改作、散佈、發行、公開發表、進行還原工程、解編或反向組譯。若會員欲引用或轉載前述軟體、程式或網站內容，必須依法取得徠能明確事前書面同意書。尊重智慧財產權是會員應盡的義務，如有違反，會員應對徠能負損害賠償責任（包括但不限於訴訟費用及律師費用等）。
      2. 在尊重他人智慧財產權之原則下，會員同意在使用徠能之服務時，不作侵害他人智慧財產權之行為。
      3. 若會員有涉及侵權之情事，徠能可暫停全部或部份之服務，或逕自以取消會員帳號之方式處理。
      4. 若有發現智慧財產權遭侵害之情事，請將所遭侵權之情形及聯絡方式，並附具真實陳述及擁有合法智慧財產權之聲明向徠能反應，若有任何疑問請與我們聯絡。

      七、外部網站買賣或交易行為
      1. 徠能確保本系統電腦系統具有一般可合理期待之安全性，但經由本系統鏈結之網站或網頁內容由各該網站或網頁廠商提供，徠能不保證其內容或所有資訊之正確性、可信度或即時性。
      2. 會員可能經由本系統連結之外部網站提供商品買賣、服務或其他交易行為。若您因此與該網頁進行交易，則該買賣或其他合約僅存於您與該網頁廠商或個人之間，並不在本系統範圍內，與本系統完全無關亦不負任何擔保責任，您不得向本系統請求任何權利。

      九、免責聲明
      您明確了解並同意本系統，均僅依各該服務當時之功能及現況提供使用。對於使用者之個人特別要求或需求，本系統不負任何明示或默示之形式或內容的擔保或保證責任，包括但不限於速度、安全性、可靠性、完整性、正確性及不會斷線和出錯、商業適售性、特定目的之適用性及未侵害他人權利等。
      除本系統有故意或重大過失者外，使用本系統所造成之任何損害，本網站均不負任何責任。

      十、其他約定
      1. 會員同意於使用本系統之所有內容，皆以電子文件作為正式表示方法，會員於各項服務與功能等頁面點選同意或確認等功能鍵時，即視為會員正式表示之意。
      2. 本公司擁有更改商品定價、售價、規格、優惠…等權利，如有變更在網站上公告，不另行個別通知，已成立並與客服確認過的訂單不在此限。
      3. 本公司網站上所有商品結帳貨幣單位均為新臺幣。
      4. 在本公司官網交易上，若產生交易糾紛，以本系統提供之資訊及電子交易資料為依據。
      5. 任何干擾本系統之行為，本公司有權要求損害賠償。
      6. 若本系統保養及施工、發生突發性設備故障時，本系統有權可以停止或中斷提供本服務。其他不可歸責於徠能之事由所致之服務停止或中斷非本公司惡意所造成本系統中斷、延遲、訂單重覆、訂購失敗等狀況，本公司無需提供使用者或任何第三人任何賠償。

      十一、準據法與解釋原則
      會員除應遵守本條款與細則外，亦同意遵守本公司於本系統及關連網站上之各項規定，各項商品之交易及本條款與細則未約定之事項，以中華民國法律及相關法令、規章、慣例為依據處理。如有發生糾紛時，以台灣台北地方法院為第一審管轄法院。
    `,
    privacy: `宙和網路資訊股份有限公司(以下簡稱「本公司」)所擁有之Linerp徠能智慧採購系統（以下稱本系統），絕對尊重各位使用者之隱私權，請詳閱下列本系統的保護聲明，以協助您瞭解本系統蒐集您個人資料之目的、類別、利用範圍及方式以及您所得行使之權利。
      本系統有權隨時修訂本隱私權保護聲明暨個資蒐集告知及相關告知事項，並得於修訂後公布於本系統官方商城之適當位置或更新本網頁內容，不另行個別通知。您可以隨時在本系統詳閱修訂後的隱私權保護聲明暨個資蒐集告知及相關說明事項。

      一、個人資料之蒐集目的、方式與適用範圍
      1. 本系統蒐集您的個人資料目的在於進行客戶與會員管理服務、商業與技術資訊、網路購物及其他電子商務服務、本系統及商品之行銷、 購買商品之運送及交付、統計調查與分析、資訊與資料庫管理。
      2. 本系統將依照個別服務性質取得使用者之姓名、地址、住家電話號碼、行動電話號碼、退款轉帳帳戶資訊等金融機構帳戶之號碼與姓名、身分證字號、外籍人士護照號碼、出生年月日、性別、年齡、國籍、使用消費品之種類及服務之細節、個人或家庭之消費模式（包含瀏覽cookie記錄）等。使用者有自由選擇提供個人資料之權利，但若您不提供時，本系統網將無法提供相關服務，亦可能無法履行與您間之會員契約權利義務。
      3. 本系統為保護您的隱私及權益，當您使用信用卡方式進行購物結帳時，付款作業系統將直接連結至信用卡收單銀行之網路刷卡介面，本系統不會記錄您在該介面輸入的任何資料，俾利維護您信用卡資料之機密性。
      4. 本系統為提供給您更快速、方便的退款服務，當您的訂單有退款情況時，本系統會請您提供訂單購買人或收件人的退款帳戶資料：銀行名稱、帳號及戶名。本系統僅將您的退款轉帳帳戶資訊，利用於退款服務。
      5. 除上述個人資料類別外，為了便利使用者，本系統可能使用cookies技術，進行電子商務網站管理及記錄您於本系統中的活動紀錄，以便於提供更適合使用者個人需要之服務。Cookies是讓網站伺服器 (Web Server) 能夠辨識使用者的資料。一般網頁被讀取時，伺服器無法辨識使用者是否曾經來過以及來過的時間次數等資料，於是瀏覽網頁時，伺服器將一段簡短資訊經由瀏覽器寫入使用者硬碟，下次瀏覽器在要求伺服器傳回網頁之前，會將Cookie的資料先傳給伺服器，伺服器可依據Cookie的資料來判斷使用者，有了使用者的造訪資料，網頁伺服器可分析並針對不同讀者之不同喜好，執行不同的動作或傳回特定的資訊。本系統會在下列情況下，在您瀏覽器中寫入並讀取Cookies：
      為提供更好、更個人化的服務，並方便您參與個人化的互動活動，例如儲存您偏好的特定領域資料，或儲存密碼以方便您至本系統時不必每次再輸入密碼。
      為統計瀏覽人數及分析瀏覽模式，以了解網頁瀏覽的情況，做為本系統改善服務的參考。
      如果您選擇拒絕所有的Cookies，您可能無法使用部份個人化服 務，或是參與部份的活動，您可以在瀏覽器中選擇修改對Cookies的接受程度，包括接受所有Cookies、設定Cookies時得到通知、拒絕所有Cookies等。

      1. 此外，本系統會保留使用者在上網瀏覽或查詢時於伺服器自行產生的相關記錄，包括連線的IP位址、使用時間、使用的瀏覽器、瀏覽以及點選的資料和紀錄等。這些資料係供本系統內部作網站流量和網路行為調查的總量分析，以利於提昇本系統的服務品質。本系統及本公司僅對全體使用者行為總和進行分析，並不會對個別使用者進行分析。
      2. 在本系統刊登廣告之廠商，或與本系統合作連結的網站，可能會蒐集使用者個人資料。這些廣告廠商或連結網站應有其個別隱私權保護聲明或措施，該規定或措施均不適用於本系統網隱私權保護聲明暨個資蒐集告知，本系統不負任何法律責任。使用者可能在本系統中的俱樂部、社群網站等提供個人資料，此部分因本系統並非蒐集者，故不在本系統隱私權保護聲明暨個資蒐集告知適用範圍內，本系統不負任何法律責任。
      3. 使用者傳送至本系統之個人資料以外的任何其他通訊或材料，包括但不限於意見、客戶回應、喜好、建議、支持、請求、問題等內容（以下簡稱「其他訊息」）視為非本條規定個人資料範圍，將以非保密資料和非專有資料處理；而當使用者將其他訊息傳送至本系統並被接收時，即被視為使用者同意且無償將其他訊息授權本系統調查、統計等使用。

      二、利用期間、地區、對象及方式
      1. 利用期間：本系統於營運期間均可能永久利用該等個人資料，但法令另有規定者，依其規定。
      2. 地區：您的個人資料將用於本系統及品牌商本公司之業務合作廠商(包含但不限於供貨商、快遞、物流商及其他履行輔助人或代理人)所在地區與提供服務地區。
      3. 利用對象及方式：
      本系統目前經營者為本公司，因此本系統將提供您的個人資料予本公司共同利用。本系統將會以電子、書面、電話、電信、網際網路及其他方式於蒐集之特定目的範圍內處理並利用個人資料。
      您的個人資料蒐集除使用於本系統之會員管理、客戶管理之檢索查詢等功能外，還會有以下利用方式：

      (1)物品及紙本發票寄送：於訂購商品或銷退商品之收、送貨，交寄相關物品時，本系統會將您的個人資料交給委託之供應商、物流業者、快遞、宅配、貨運業者等，以利進行配送物品服務。
      (2)行銷活動：本系統將利用使用者之地址及郵件、電話號碼進行本系統商品或活動宣傳（包括但不限於本系統商業性資料寄送，如電子報、EDM、簡訊）、通知您相關服務之更新及優惠訊息、提供使用者各種電子資訊等與服務有關之行銷資訊或針對民調、活動、留言版等之意見資訊交換等服務關連事項、聯絡使用者。
      (3)會員專屬服務：會員專區服務、購物紀錄、訂單資料、電子發票、退換貨及售後服務等辦理各項會員服務所需。
      (4)商業及市場的分析:將使用者所瀏覽之內容或廣告，依使用者之性別、年齡、居住地、興趣、嗜好等個人屬性或購買紀錄、網站之瀏覽紀錄等項目，進行使用服務之分析、新服務之開發與既有服務之改善等。
      (5)本系統與品牌商本公司會利用使用者的個人資料於本系統會員活動、會員購買商品服務之統計分析、網路行銷活動、調整或發布商品資訊。
      (6)客製化您看到的網頁內容、滿足您對產品和服務的要求、改進我們的服務、聯絡您、進行研究，以及提供內部及外部客戶不載有個人資料之市場分析或業務報告。
      (7)未來如本系統之營運權利移轉予本系統品牌擁有者或其他第三人時，為讓您得以繼續使用本系統之服務，本公司會將您的會員資料及交易紀錄移轉予後續負責營運本系統之人。
      (8)其他另經過使用者本人以書面、電子郵件、傳真或網頁點選按鈕同意之特定目的之利用。
      三、使用者權利行使
      1. 謹簡要說明，您依個人資料保護法第3條所得行使之權利，及本系統處理原則如下：
      2. 您在本系統註冊會員後，您得利用註冊帳號和密碼查詢您所提供的個人資料狀況。
      3. 您可以隨時利用註冊帳號和密碼補充或變更先前輸入的個人資料，惟必要時，本系統得要求您為適當之釋明。
      4. 您可以寄發電子郵件至客服信箱或致電客服中心申請停止蒐集、處理、利用或刪除個人資料。但使用者於申請停止蒐集、處理或利用個人資料，或請求刪除個人資料等程序完成後，本系統提供給使用者的服務將暫停或終止，本系統將在法令許可範圍內辦理相關事宜。
      5. 若您不願意提供各項服務或交易所要求的個人資料予本系統，或不同意本系統依法令規定、本隱私權保護聲明暨個資蒐集告知及其相關告知內容為蒐集、處理、利用及國際傳輸相關個人資料，本系統會完全尊重您的決定，但依照該服務之性質或條件，您可能會因此無法使用相關服務、履行權利義務或完成交易(例如：若您拒絕提供生日，將不能註冊會員也無法享有生日回饋禮)，本系統保留是否同意提供該等相關服務或完成相關交易之權利。

      四、揭露個人資料
      1. 本系統所取得之個人資料會視業務需要提供予本系統販售商品之品牌商本公司、本系統委外之協力廠商(包括但不限於供貨商及提供物流、快遞、金流或活動贈品、展示品之廠商及其他履行輔助人或代理人) 於公告之特定目的範圍內予以利用。
      2. 本系統絕不會任意出售、交換或出租您的個人資料給其他團體或個人，僅供內部進行處理和利用，但有下列情形者，不在此限，而本系統亦毋需事先告知使用者：
      - 需要與他人或公司共用您的資料，才能提供您要的產品或服務。
      - 基於法律規定或受司法機關與其他主管機關基於法定程序之要求。
      - 為維護國家安全、增進公共利益、防止他人權益之重大危害。
      - 為免除使用者之生命、身體、自由或財產上之危險，有利於當事人權益。
      - 經過使用者本人書面同意或授權允許。
      - 基於善意相信揭露為法律需要。
      - 負責營運本系統之人變更，為讓使用者得以繼續使用本系統之服務，而將使用者的會員資料及交易紀錄移轉予後續負責營運本系統之人。
      - 為維護和改進網站服務而用於管理。
      1. 為保護使用者隱私，本系統無法為個人查詢其他使用者的帳號資料，若有相關法律問題需查閱他人資料，請務必先向警政單位提出刑事告訴，本系統將全力配合檢警與司法單位因偵辦案件所需而提供所有相關資料，以協助調查及破案。
      2. 為提供其他服務或優惠權益，本系統需要跟提供該服務或優惠之第三者共用您的個人資料時，我們會在活動時提供充分說明並告知您，您可以自由選擇是否接受這項服務或優惠。

      五、資料儲存及保管方式
      您的個人資料均被嚴密地保存在本系統資料庫系統中。同時，任何人必須在本系統訂定之資料授權管理規範下，進行資料之取得與使用。非授權範圍內之人無法通過授權控管系統而取得資料。

      六、個人資料安全
      本系統將以合於產業標準之合理技術及程序，維護個人資料之安全。

      七、自我保護措施
      請妥善保管您的密碼及或任何個人資料，不要將任何個人資料，尤其是密碼提供給任何人。在您離開電腦前或結束使用本系統的服務時，請務必記得登出帳號，若您與他人共享電腦或使用公共電腦，請記得關閉瀏覽器視窗，以防止他人讀取您的個人資料或信件。

      八、隱私權保護聲明諮詢
      若對本系統的隱私權政策暨個資蒐集通知有任何問題，請利用”聯絡我們”反映，我們將儘速回覆。
      
      `,
  }),

  computed: {
    isMainManager() {
      return this.$store.getters['member/isProviderMainManager']
    },
    providerId() {
      return this.$store.getters[`member/providerId`]
    },
    memberId() {
      return this.$store.getters[`token/memberId`]
    },
  },
  methods: {
    async beforeFormInit() {
      await Promise.all([])
    },
    validateBeforeSave() {
      // TODO
    },
    afterSaveSuccessCallback(result) {

    },
    async readApi(target) {
      const res =  await this.$api.collection.providerApi.read(this.providerId)
      if (res.terms.length === 0) {
        res.terms = this.terms
      }

      if (res.privacy.length === 0) {
        res.privacy = this.privacy
      }

      const announce = await this.$api.collection.providerApi.getAnnounce(this.providerId);
      const announceContent = announce.find((e)=>e.type==="PROVIDER_STORE")
      if (announceContent.content) {
        res.announce = announceContent.content
      }

      return res
    },
    async updateApi(target, formData) {

      await this.$api.collection.providerApi.MessageTextUpdate(this.providerId, formData)
      const res =  await this.$api.collection.providerApi.read(this.providerId)
      this.$store.dispatch('base/setLayoutProvider', res)

    },
    getFormConfig() {
      return formConfig
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
